body {
  font-family: "Lato", Helvetica, Arial, sans-serif !important;
  overflow: auto !important;
}
.profile-phone .half-phone .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  top: -5px !important;
}
th {
  text-align: center!important;
}
td {
  text-align: center;
}
.css-1ontqvh {
  padding: 0!important;
}
.main-section button.btn.btn-primary {
  padding: 13px 25px;
  border-radius: 45px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}
.footer {
  position: fixed !important;
}
.table td,
.table th {
  padding: 0.75rem 0.6rem !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: "Lato", Helvetica, Arial, sans-serif !important; */
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

p,
span,
div,
Button,
button,
text,
textarea {
  /* font-family: "Lato", Helvetica, Arial, sans-serif !important; */
  font-family: "Lato", Helvetica, Arial, sans-serif;
}

.bg-danger {
  background-color: #c93432 !important;
}

.card-body {
  font-size: 14px;
  letter-spacing: 0.03rem;
  font-family: "Lato", Helvetica, Arial, sans-serif !important;
}

.bg-black {
  background: #000000 !important;
}

.bg-red {
  background: #c83232 !important;
}
.red-color {
  color: #c83232 !important;
}
.bg-white {
  background: #ffffff !important;
}
.bg-whatsapp {
  background-color: #dbf3c6 !important;
}
.bg-gold {
  background: #e6a851 !important;
}

.color-gold {
  color: #e6a851 !important;
}

.bg-gray {
  background: #191c21 !important;
}
.bg-gray-light {
  background: #eee !important;
}

.bg-green {
  background-color: #008000 !important;
}
.green-color {
  color: #008000 !important;
}

.gold-color {
  color: #e6a851 !important;
}

.white-color {
  color: #ffffff !important;
}

.blue-color {
  color: #000000 !important;
}
.blue-color:hover {
  color: #147cfa !important;
}

.black-color {
  color: #000000 !important;
}

img {
  max-width: 100% !important;
}

h1 {
  font-weight: 700 !important;
  line-height: 1.25;
  font-size: 1.6rem;
  vertical-align: baseline;
  letter-spacing: 0.03em;
}

h2 {
  font-weight: 700 !important;
  line-height: 1.25;
  font-size: 1.2rem !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  letter-spacing: 0.03em;
  vertical-align: baseline;
}
table p{
  font-size: 13px;
}
p {
  letter-spacing: 0.03em;
  line-height: 1.2;
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 1rem;
}

table {
  font-size: 13px;
  letter-spacing: 0.03em;
  line-height: 1.7;
  font-weight: 500;
}

button.btn.btn-primary {
  color: #ffffff;
  outline: none !important;
  border: none !important;
}

.border-radius-4 {
  border-radius: 4px;
}
.border-radius-15 {
  border-radius: 15px;
}
.border-radius-25 {
  border-radius: 25px;
}
.border-radius-100 {
  border-radius: 100%;
}

.border-bottom-header {
  border-bottom: 0.1px solid #353535 !important;
}
.border-right-sidebar {
  border-right: 0.1px solid #353535 !important;
}
.border-top {
  border-top: 0.1px solid #353535 !important;
}

.border-right {
  border-right: 0.1px solid #353535 !important;
}
/*.fade.modal.show {
  z-index: 9999!important;
}*/
.main-section {
  left: 16.7%;
  padding-top: 70px;
  padding-right: 45px !important;
  padding-left: 45px !important;
  padding-top: 120px !important;
  padding-bottom: 45px;
}
body::-webkit-scrollbar {
  display: none;
}
p.sidebar-text-logo img {
  height: auto;
  width: 145px;
}
p.sidebar-text-logo {
  top: -60px;
  position: absolute;
  left: 12%;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  position: absolute;
  left: 88%;
  display: none;
}
button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-edgeStart.MuiIconButton-sizeMedium.css-p55zm9-MuiButtonBase-root-MuiIconButton-root {
  margin-right: -15px !important;
}
body {
  background-color: #eee !important; /*#edf4f8*/
}
.bg-gray-new {
  background: #b3b3b3;
}
.page-heading {
  border-radius: 2px;
  padding: 6px 0px;
  /* border-bottom: 1px solid #dee2e6; */
  margin-bottom: 20px;
  letter-spacing: 0.03rem;
  font-size: 20px !important;
  color: #3c4858 !important;
  font-weight: 500;
}
table svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #000000;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  /* background: #000000; */
}
button.bg-green {
  background-color: #0c900c!important;
}
button.bg-green:hover {
  background-color: #086e08!important;
}
.css-135g1k9-MuiButtonBase-root-MuiButton-root {
  transition: box-shadow 0.3s;
  letter-spacing: 0.05rem !important;
  padding: 7px 35px !important;
  text-align: center;
  transition: all 0.5sease 0s;
  background: #000000 !important;
  border-radius: 5px !important;
  display: block;
  font-weight: 600 !important;
  cursor: pointer;
  touch-action: manipulation;
  color: #ffffff !important;
  font-size: 16px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.css-1tnhhup {
  transition: box-shadow 0.3s;
  letter-spacing: 0.05rem !important;
  padding: 7px 70px !important;
  text-align: center;
  transition: all 0.5s ease 0s;
  background: #000000 !important;
  border-radius: 8px !important;
  display: block;
  font-weight: 700 !important;
  cursor: pointer;
  touch-action: manipulation;
  color: #ffffff !important;
  font-size: 18px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.city-onboard-tutor fieldset {
  height: 58px;
}
/* .student-list-modal .PhoneInput > :first-child {
  height: 53px;
  margin: 3px 0px 0px 0px !important;
} */

.css-135g1k9-MuiButtonBase-root-MuiButton-root:hover {
  background-color: #070606!important;
}
.mdlt .form-control,
.form-control:focus,
.form-control:disabled,
.form-control[readonly] {
  background: #ffffff !important;
  border-color: #b1b3b1;
  font-size: auto !important;
}

.width-100 {
  width: 100% !important;
}
.width-95 {
  width: 95% !important;
}
.width-90 {
  width: 90% !important;
}
.width-80 {
  width: 80% !important;
}
.width-70 {
  width: 70% !important;
}
.width-50 {
  width: 50% !important;
}
.width-45 {
  width: 45%;
}
.width-30 {
  width: 30% !important;
}
.width-35 {
  width: 35% !important;
}
.width-25 {
  width: 25% !important;
}
.width-20 {
  width: 20% !important;
}
.width-15 {
  width: 15% !important;
}
.width-13 {
  width: 13% !important;
}
.width-12 {
  width: 12% !important;
}
.width-10 {
  width: 10% !important;
}
.width-8 {
  width: 8% !important;
}
.width-60 {
  width: 60%;
}
.width-40 {
  width: 40%;
}
a:hover {
  /*color: #e6a851 !important;*/
  text-decoration: none !important;
}
.border-none {
  border: none !important;
}
/*.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    background-color: #c83232 !important;
    color: #fff;
    border-radius: 4px;
    border: none;
}*/
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  color: #494949 !important;
}
.meeting-button .btn-primary {
  transition: box-shadow 0.3s;
    letter-spacing: 0.05rem;
    padding: 7px 12px !important;
    text-align: center;
    transition: all 0.5s ease 0s;
    background: rgb(227, 38, 82);
    border-radius: 980px !important;
    display: block;
    font-weight: 700;
    cursor: pointer;
    touch-action: manipulation;
    font-size: 13px !important;
    white-space: nowrap;
}
.meeting-button .btn-primary:hover {
    background: #a61b3c!important;
}
/* fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
  height: 58px;
} */
.onboard-modal-cust .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding-bottom: 6px;
}
.modal-dialog .btn-primary {
  color: #fff !important;
    background: rgb(227, 38, 82);
    padding: 10px 18px !important;
    border-radius: 5px !important;
    font-size: 13px;
}
.onboard-phone .PhoneInputCountrySelect {
  color: #000000;
}
.PhoneInputCountrySelect {
  color: #000000;
}
/* .css-ynw3li-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root fieldset {
  top: -2px !important;
  height: 57px !important;
} */

.login .btn-primary {
  color: #fff !important;
    margin-top: 46px !important;
    background: #e32652;
    font-size: 16px;
    font-weight: 600 !important;
    letter-spacing: 0.03rem;
    border-radius: 8px !important;
    padding: 12px 20px !important;
}
.btn-primary {
  color: #fff !important;
  /* box-shadow: rgb(192, 16, 81) 0px 0px 14px -7px;
  background-image: linear-gradient(45deg, rgb(200, 50, 35) 0%, rgb(230, 168, 81) 51%, rgb(200, 50, 50) 100%); */
}

.login .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 23px 15px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 0 0 1px #949aa4;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.login .form-group .form-control:focus {
  color: #495057;
  padding: 23px 15px!important;
  background-color: #fff !important;
  box-shadow: inset 0 0 0 1px #949aa4!important;
  border: none!important;
  width: 100% !important;
}

.login label.form-label {
  color: #000000;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 3px;
}
/*.btn-primary:hover {
    color: #fff !important;
    background-color: #c83232 !important;
}*/

.btn-primary {
  border: none;
}

.modal-dialog {
  margin: 4.75rem auto !important;
}

.mdlt .form-control,
.form-control:focus,
.form-control:disabled,
.form-control[readonly] {
  font-size: 1rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgb(201 52 50 / 14%) !important;
  border: 1px solid #c9343226 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn.focus,
.btn:focus {
  text-decoration: none !important;
  box-shadow: none !important;
}

.form-control {
  font-size: 14px !important;
  letter-spacing: 0.03rem;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.mdlt .form-control,
.form-control:focus,
.form-control:disabled,
.form-control[readonly] {
  font-size: 14px !important;
}

.table td {
  vertical-align: middle !important;
  border: 1px solid #dee2e6;
}

.table th {
  vertical-align: middle !important;
  border: 1px solid #dee2e6;
}

a {
  color: #147cfa;
}
.modal-header h5 {
  font-weight: 500;
  letter-spacing: 0.03rem;
  font-size: 18px;
}
#zmmtg-root {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: -99999 !important;
  left: 0;
  background: none !important;
  background-color: black;
}
.tooltip-inner {
  max-width: 200px;
  padding: 5px 7px !important;
  font-size: 14px;
  letter-spacing: 0.05rem;
}
/*.tooltip .arrow {
  display: none !important;
}*/
/*.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #e6a851;
}*/
.main-section input[type="text"]:focus {
  outline: none !important;
  outline-offset: none !important;
}
.modal-header h5 {
  margin-bottom: 0;
}
.box-shadow {
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  border: 0.1px solid #80808057;
}
.essay-d-filter thead {
  background: #000000;
  color: #ffffff;
  display: inline-table;
  margin: auto;
  padding: 15px;
  width: 100%;
  text-align: center;
}
.essay-d-filter thead .go2139093995.multi-select {
  height: 35px;
}
.essay-d-filter thead .go2642161244.dropdown-heading {
  height: 33px;
}
.essay-d-filter thead tr th .form-group {
  margin: 0px 30px;
}
/*.essay-d-filter thead tr th select{
  width: fit-content;
}*/
thead {
  background: #34304b;
  color: #ffffff;
}
.border-none {
  border: none;
}
.modal-header {
  border: none !important;
}
.modal-footer {
  border: none !important;
}
/*.modal-content {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}*/
.MuiFormControl-root {
  border: 0;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 100% !important;
  flex-direction: column;
  vertical-align: unset;
}
/*.MuiInput-root {
  position: relative;
  padding: 0px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none !important;
}*/
input[type="text"]:focus {
  outline: none !important;
}
/*
.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}*/

/*   after lazy loading  */

.small-p {
  font-size: 10.5px;
  opacity: 1;
}
select {
  background-color: white;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: none;
  color: #ffffff;
  padding: 10px 30px 10px 10px;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  /*&::-ms-expand { display: none };*/
}

.main-section select:focus {
  background-color: white;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: none !important;
  padding: 10px 30px 10px 10px;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
/*.go3425554998.dropdown-content {
  background-color: #ffffff!important;
  z-index: 999!important;
}*/
.go2642161244.dropdown-heading {
  background-color: white;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: none;
  padding: 5px 30px 5px 10px;
  outline: none !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.gray {
  color: #fff;
  display: none;
}

.activeListing {
  color: #c83232;
}
.form-group .form-control:focus {
  color: #495057;
  padding: 0.375rem 0.75rem !important;
  background-color: #fff !important;
  border: none !important;
  width: 100% !important;
}
a {
  border: none !important;
  outline: none !important;
}
.accordion .card-header {
  background: #d6b770;
}
.card-header button {
  width: 100%;
  text-align: left;
}
.accordion button {
  color: #000;
}
.accordion .btn-link:hover {
  color: #000 !important;
  text-decoration: none !important;
}
.modal-dialog.modal-90w.modal-lg {
  width: 95%;
  max-width: none !important;
}
img.log-logo {
  width: 40px !important;
}

tr.hide {
  visibility: hidden !important;
}

i.fa.fa-trash {
  color: #e6a851 !important;
}
i.fa.fa-trash {
  /* font-size: 22px; */
  font-size: 16px;
  cursor: pointer;
}

.btn-heart {
  background: none !important;
  color: #c83232 !important;
  border-radius: 2px;
  border: none;
  font-size: 22px !important;
}

.btn-heart-grey {
  background: none !important;
  color: #babdc2 !important;
  border-radius: 2px;
  border: none;
  font-size: 22px !important;
}

button.edit-color {
  background: #008000 !important;
}
.modal-body {
  padding-top: 0 !important;
}
.form-group {
  margin-bottom: 0rem !important;
}
table p {
  margin-bottom: 0rem !important;
}
.sidebar-for-mobile {
  display: none;
}
.sidebar {
  display: block;
}
/*.form-group .form-control:focus {
  color: #495057;
  padding: 0.375rem 0.75rem !important;
  background-color: #fff !important;
  border: none !important;
  width: auto!important;
}*/

@media screen and (min-width: 900px) and (max-width: 1440px) {
  .rdw-editor-toolbar {
    width: 53.4% !important;
    margin-bottom: -20px !important;
  }
  .sidebar-height-compose {
    height: 710px !important;
    max-height: fit-content;
    margin-bottom: 25px !important;
    overflow: auto;
  }
  ul.MuiList-root.MuiList-padding.text-area-height.css-1mcnwpj-MuiList-root {
    height: 710px !important;
    max-height: fit-content;
    margin-bottom: 25px !important;
    overflow: auto;
  }
}
/*  Mobile  */

@media only screen and (max-width: 600px) {
  .main-section {
    left: 0%;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .sidebar-for-mobile {
    display: block;
  }
  .sidebar {
    display: none;
  }
  .dropdown-menu {
    background-color: #191c21 !important;
  }
  #menuToggle a {
    color: #ffffff !important;
  }
  .mob-nav button.navbar-toggler.collapsed {
    display: none;
  }
  .mob-nav nav.navbar {
    margin-left: 10%;
  }
  .sidebar-for-mobile #menuToggle {
    top: 20px !important;
    left: 25px !important;
    z-index: 999 !important;
    position: fixed;
  }
  .my-class-mob {
    margin-left: 10%;
  }
  .sidebar-for-mobile .dropdown-menu.show {
    position: inherit !important;
    top: -30% !important;
  }
  a#uncontrolled-tab-example-tab-previous {
    margin-left: 0px !important;
  }
  .member-list {
    position: relative !important;
    width: 100% !important;
  }
  .chat-section {
    position: relative !important;
    width: 100% !important;
    height: 100%;
    left: 0 !important;
  }
  .fixed-chat-box {
    width: 100% !important;
    bottom: 6% !important;
  }
  th.text-left.d-flex.search-flex {
    display: grid !important;
  }
  .reach-bg.col {
    margin: 5px 0px;
  }
  .target-bg {
    margin: 5px 0px;
  }
  .safety-bg {
    margin: 5px 0px;
  }
  .shortlisted-bg {
    margin: 5px 0px;
  }
  .form-group {
    margin: 5px 0px;
  }
  p.text-value {
    margin: 5px 0px;
  }
  span.college-search {
    text-align: center;
  }
  .width-70 {
    width: 100% !important;
  }
  a#uncontrolled-tab-example-tab-previous {
    padding-left: 0px;
  }
}

/* i-pad */

@media only screen and (device-width: 768px) {
  .main-section {
    left: 25%;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  .bg-black.border-bottom-header.mob-nav .navbar-collapse.collapse.show {
    display: inherit;
  }
}

@media screen and (min-width: 810px) and (max-width: 1180px) {
  .main-section {
    left: 25%;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.form-check-input {
  margin-left: -1rem !important;
}

/*  new UI modal  */

.modal-dialog {
  max-width: 700px !important;
}

.modal-content {
  border: 1px solid #000000 !important;
  border-radius: 2px !important;
}
.fade.modal.show {
  background: #000000de;
}
.accordion .card {
  overflow: inherit !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
}

/*  new search */

.input-group-text {
  color: #ffffff !important;
  background-color: #c93432 !important;
  border: 1px solid #c93432 !important;
}
.PhoneInput input {
  font-size: 1rem;
}

/* Notification */

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(214 183 112) !important;
}
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before {
  display: none !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiPopover-paper.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 40px !important;
}
.sidebar-wrap {
  z-index: 9;
}
.dropdown-toggle:hover .dropdown-menu.show {
  display: block !important;
}
.sticky-col {
  z-index: 1 !important;
}
.mrgtop span i {
  font-size: 16px;
}
.mrgtop span {
  padding: 8px 12px !important;
  margin-top: -5px !important;
}
.faq .accordion .card-header {
  background: #d6b770;
  padding: 4px 20px;
}

/* button branch  */
.advisor-side-img-icon {
  background: #ffffff;
}
.top-btn-sec {
  padding-left: 0px !important;
  margin-bottom: 20px;
}
.top-btn-sec button {
  padding: 10px 40px !important;
}
.advisor-side-img-icon {
  border-radius: 2px;
}
.advisor-right-side-text-section {
  padding: 15px;
}
.advisor-side-img-icon {
  padding: 20px;
  text-align: center;
}
.box-section-note {
  border: 1px solid rgb(183 183 183);
  border-radius: 4px;
  margin: 15px 0px;
}
.advisor-side-img {
  width: 81px;
}
button.rounded.btn.btn-primary {
  transition: box-shadow 0.3s;
    letter-spacing: 0.05rem;
    padding: 7px 12px;
    text-align: center;
    transition: all 0.5s ease 0s;
    background: rgb(227, 38, 82);
    border-radius: 25px !important;
    display: block;
    font-weight: 700;
    cursor: pointer;
    touch-action: manipulation;
    font-size: 13px;
}
td.cal-btn button.rounded.btn.btn-primary {
  border-radius: 2px !important;
  font-weight: 700;
  line-height: 1.25;
  transition: box-shadow 0.3s;
  padding: 7px 12px;
  width: 70px;
}
button.rounded.btn.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 1px 1px #0000001c, 0 2px 2px #0000001c, 0 4px 4px #0000001c,
    0 6px 8px #0000001c, 0 8px 16px #0000001c;
  border-radius: 40px;
}
button.btn.btn-primary {
  border-radius: 2px;
  font-weight: 700;
  /* line-height: 1.25; */
  transition: box-shadow 0.3s;
  padding: 7px 12px;
}
button.btn.btn-primary:hover {
  /* transform: translateY(-3px); */
  /* box-shadow: 0 1px 1px #0000001c, 0 2px 2px #0000001c, 0 4px 4px #0000001c,
    0 6px 8px #0000001c, 0 8px 16px #0000001c; */
    background-color: #a61b3c;
}
button.btn.bg-danger {
  border-radius: 5px !important;
  font-weight: 700;
  line-height: 1.25;
  transition: box-shadow 0.3s;
  padding: 7px 15px;
}
button.bg-green {
  border-radius: 5px !important;
  font-weight: 700;
  line-height: 1.25;
  transition: box-shadow 0.3s;
  padding: 7px 15px;
}
button.btn.bg-danger:hover {
  /* transform: translateY(-3px); */
  /* box-shadow: 0 1px 1px #0000001c, 0 2px 2px #0000001c, 0 4px 4px #0000001c,
    0 6px 8px #0000001c, 0 8px 16px #0000001c; */
  border-radius: 40px;
}
.gpasbmt button {
  border-radius: 32px !important;
  font-weight: 700;
  line-height: 1.25;
  transition: box-shadow 0.3s;
  padding: 7px 15px;
}
.gpasbmt button:hover {
  transform: translateY(-3px);
  box-shadow: 0 1px 1px #0000001c, 0 2px 2px #0000001c, 0 4px 4px #0000001c,
    0 6px 8px #0000001c, 0 8px 16px #0000001c;
  border-radius: 40px;
}

@media print {
  * {
    /* padding: 0 !important; */
    /* margin: 0 !important; */
    box-sizing: border-box !important;
  }
}

@media print {
  thead {
    display: table-header-group;
  }
}
@media print {
  table {
    /* background-color: #1a4567 !important; */
    print-color-adjust: exact;
  }
}

.css-1wx33xz-control:hover {
  border-color: #000000 !important;
}
.css-1wx33xz-control {
  box-shadow: none !important;
}
.mtl-select.form-group {
  max-width: 100%;
  width: 145px !important;
}

.new-slect-validation select {
  width: 139px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-top: -5% !important;
  margin-left: 5% !important;
}

.css-7srohi-control {
  margin-top: -3%;
}
.css-13sqlul-control {
  margin-top: -3%;
}
.css-1mgva5z-control {
  margin-top: -3%;
}
.css-42fwp-control {
  background: #fd9a13 !important;
  margin-top: -2% !important;
  color: #ffffff !important;
  border: 1px solid #fd9a13 !important;
}
.css-1xc3v61-indicatorContainer {
  color: #ffffff !important;
}
.css-1nlg94l-singleValue {
  letter-spacing: 0.05rem;
  font-weight: 600 !important;
  font-size: 16px !important;
}
.select-dpdn {
  position: absolute !important;
  right: 1.5% !important;
  width: 192px;
}
.css-7srohi-control {
  margin-top: -3%;
}
.css-1nmdiq5-menu {
  width: 182px !important;
  margin-left: 5% !important;
  padding: 0 !important;
  margin-top: 0 !important;
}
#tooltip-top > .tooltip-inner {
  padding-top: 15px;
  display: -ms-inline-grid !important;
}

.meeting-list.col input#default-checkbox {
  margin: 4px 0 0;
  line-height: normal;
  width: 15px !important;
  height: 15px !important;
  /* cursor: pointer; */
}
.meeting-list button {
  margin: auto;
}
.tooltip-info {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip-info .tooltip-info-text {
  visibility: hidden;
  color: #000000;
  background: #ffffff;
  border: 1px solid #aaaaaa;
  width: 600px;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
  font-weight: 500;
  letter-spacing: 0.05rem;
  border-radius: 15px 0px 15px 15px;
  font-size: 18px;
  padding: 15px 10px;
  margin-top: 15px;
}

.tooltip-info .tooltip-info-text::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.tooltip-info:hover .tooltip-info-text {
  visibility: visible;
}

.select-dpdn {
  width: 330px;
}
.css-1nmdiq5-menu {
  width: 320px !important;
  margin-left: 10px !important;
}

.manger-modal-23.modal .modal-dialog.modal-lg {
  max-width: 1080px !important;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: flex-start;
  background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 31px;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  bottom: 0;
  margin-left: -10px;
  width: 100% !important;
}

ul.MuiList-root.MuiList-padding.css-1mcnwpj-MuiList-root::-webkit-scrollbar {
  display: none;
}
table.text-center.custom-width-form-group.table.table-striped input {
  border-radius: 5px;
  padding: 4px 10px;
}
.page-link:focus {
  box-shadow: none !important;
}
ul.pagination .page-item:first-child .page-link {
  display: none;
}

.close {
  /* color: #ffffff !important; */
  text-shadow: 0 1px 0 #fff;
  opacity: 0.9 !important;
}
.manager-home-table ul.pagination .page-item:last-child .page-link {
  margin-left: 50px;
}

.manager-home-table ul.pagination {
  justify-content: center;
}
.sidebar-height-compose {
  height: 690px;
  max-height: fit-content;
  margin-bottom: 25px !important;
  overflow: auto;
}
ul.MuiList-root.MuiList-padding.text-area-height.css-1mcnwpj-MuiList-root {
  height: 690px;
  max-height: fit-content;
  margin-bottom: 25px !important;
  overflow: auto;
}
.react-datepicker__input-container input {
  text-align: center;
}

.pre-board-page.board-welcome--container.board-welcome--button-container {
  flex-basis: 47%;
  /* display: flex; */
  display: none;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

span.board-welcome--button-container.link {
  display: none !important;
}


/* Override css */

span.MuiTouchRipple-root.css-8je8zh-MuiTouchRipple-root {
  display: none;
}



/* Test  */

/* app.css */

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 13px 14px!important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top: -5px!important;
  }
input#price-0 {
    height: 21px;
  }
  
.onboard-modal-cust .onboard-phone .PhoneInput > :first-child {
    z-index: 999;
    border: 1px solid black;
    border-right: 0px !important;
    text-align: center;
    vertical-align: middle;
    padding-left: 10px;
    border-radius: 3px;
    margin: 0px 0px 0px 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }
  .PhoneInput > :first-child {
    height: 53px!important;
  }
  .font-bold {
    font-weight: bold;
  }
  
.search-by-stu input {
    height: 18px;
  }
  
.search-by-tut input {
    height: 18px;
  }
  
.onboard-student-phone .PhoneInput > :first-child {
    height: 56px!important;
  }
  
.css-qiwgdb {
    padding: 13px 14px!important;
  }
  
.mt-4.hme-profile.col .PhoneInput > :first-child {
    height: 56px!important;
    margin: 0px 0px 0px 0px !important;
  }
  .half-phone .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    top: -2px!important;
  }
  .half-phone .css-igs3ac {
    top: -2px!important;
  }
  .profile-phone .css-igs3ac {
    top: -2px!important;
  }
  .css-igs3ac {
    top: -5px!important;
  }
  .onboard-student-phone .half-phone .PhoneInput > :first-child {
    height: 53px!important;
    margin: 3px 0px 0px 0px !important;
  } */


/* tutoring css */
/* .onboard-modal-cust .modal-content input {
    padding: 15px 0px;
  } */
